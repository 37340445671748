export const normalizeGamesWorkshop = (data) => {
    return data.map((product) => {
        return {
            barcode: product['Barcode'],
            sku: product['SS Code'],
            name: product['Description'],
            purchasePrice: product['EUD'],
        };
    });
}
