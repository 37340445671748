import { Button, Dialog, Pane, TextInputField } from "evergreen-ui";
import { useState } from "react";
import Request from "../../../../util/Api";



export const SimonSchusterImport = (props) => {
    const [isbn, setIsbn] = useState("")
    const [ loading, setLoading ] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const ssData = Request.post(`/integrations/simon-schuster/find-by-isbn`, null, null, {isbn: isbn.replace(/-/g, "")});
        const isbnData = Request.get(`/integrations/isbndb/find-by-isbn?isbn=${isbn.replace(/-/g, "")}`);

        const xData = await Promise.all([ssData, isbnData]);

        const data = {
            ...xData[1].result,
            ...xData[0].result
        }

        setLoading(false);
        setIsbn("");

        if(data) {
            props.onImport(data);
            props.onClose();
        }
    }

    return (
        <Dialog
            isShown={props.open}
            title="Importa din Simon & Schuster"
            onCloseComplete={props.onClose}
            onConfirm={fetchData}>
            <Pane>
                <Pane display="flex" gap={10}>
                    <TextInputField
                        placeholder="ISBN"
                        value={isbn}
                        onChange={(e) => setIsbn(e.target.value)}/>
                    <Button marginTop={8} onClick={fetchData}>Import {loading ? '(loading ...)' : ''}</Button>
                </Pane>
            </Pane>
        </Dialog>
    )
}
