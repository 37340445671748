import { Badge, Checkbox, Heading, Label, Pane, SelectField, Small, Text, Textarea, TextInputField } from "evergreen-ui";
import { Field } from "formik";
import { useEffect } from "react";
import KFileDrop from "../../../common/FormComponents/KFileDrop";
import KRichTextArea from "../../../common/FormComponents/KRichTextArea";
import ProductPicker from "../ProductPicker";
import { ComponentForm } from "./ComponentForm";
import { DynamicAttributes } from "./DynamicAttributes";


export const MainTab = ({errors, values, handleChange, handleBlur, setFieldValue, categories, suppliers, category, onCategoryChange, generateMetas }) => {
    useEffect(() => {

        if(!category || !Array.isArray(values.attributes)) {
            return
        }
        console.log(category, values, values.attributes)
        const newAttributes = values.attributes.filter((attr) => {
            return category.attributes.find((catAttr) => catAttr.id === attr.id);
        });

        setFieldValue("attributes", newAttributes);
    }, [category])
    return (<>
        <Pane display="flex" width="100%">
            {/* Left side */}
            <Pane
                borderBottom="default"
                paddingBottom={8}
                marginBottom={8}
                flexGrow={0.8}
                marginRight={20}

            >
                <TextInputField
                    required
                    type="title"
                    label="Nume"
                    name="title"
                    inputHeight={48}
                    placeholder="Numele produsului"
                    value={values.title || ""}
                    onChange={(evt) => {
                        setFieldValue("title", evt.target.value);
                        generateMetas({
                            ...values,
                            title: evt.target.value
                        }, setFieldValue)
                    }}
                    onBlur={handleBlur}
                    isInvalid={!!errors.title}
                    validationMessage={errors.title}
                />

                 <SelectField
                    label="Categorie"
                    name="categoryId"
                    value={values.categoryId}
                    required
                    onChange={(e) => {
                        onCategoryChange(
                            e.target.value,
                            setFieldValue
                        );
                    }}
                    isInvalid={!!errors.categoryId}
                    validationMessage={errors.categoryId}
                    onBlur={handleBlur}
                    inputHeight={48}
                    >
                    <option value="">Selecteaza o categorie</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                        {category.label || category.title}
                        </option>
                    ))}
                </SelectField>

                <Pane display="flex" gap={10}>
                    <TextInputField
                        flexGrow={1}
                        onWheel={(e) => e.target.blur()} // Disable mouse wheel
                        inputHeight={48}
                        required
                        label="Pret (RON)"
                        name="sourcePrice"
                        type="number"
                        placeholder="Pretul de baza al produsului"
                        value={values.sourcePrice}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.sourcePrice}
                        validationMessage={errors.sourcePrice}
                    />

                    <SelectField
                        label="Cota TVA"
                        name="vatBracket"
                        value={values.vatBracket}
                        required
                        isInvalid={!!errors.vatBracket}
                        validationMessage={errors.vatBracket}
                        onChange={(e) => {
                            setFieldValue('vatBracket', e.target.value);
                        }}
                        onBlur={handleBlur}
                        inputHeight={48}
                        >
                        <option value="">Selecteaza o cota</option>
                        <option value="5">5% (Carti)</option>
                        <option value="9">9% (Alimente fara zahar)</option>
                        <option value="19">19% (Majoritatea produselor)</option>
                    </SelectField>
                </Pane>

                <Label
                    htmlFor="description"
                    marginBottom={4}
                    display="block"
                    >
                    Descriere
                </Label>

                <KRichTextArea
                    onChange={(content, delta, ops, api) => {
                        setFieldValue("metaDescription", api.getText());
                        setFieldValue("description", api.getHTML());
                    }}
                    value={values.description}
                />

                <Pane display="flex" marginTop={20} gap={10}>
                    <TextInputField
                        required
                        type="text"
                        label="SKU"
                        name="sku"
                        value={values.sku || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.sku}
                        validationMessage={errors.sku}
                        inputHeight={48}
                        flexGrow={1}
                    />

                    <TextInputField
                        required
                        type="text"
                        label="Cod de bare"
                        name="barcode"
                        value={values.barcode || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.sku}
                        validationMessage={errors.barcode}
                        inputHeight={48}
                        flexGrow={1}
                    />

                    <SelectField
                        label="Disponibilitate"
                        name="availability"
                        value={values.availability}
                        required
                        onChange={(e) => {
                            setFieldValue("availability", e.target.value);
                        }}
                        isInvalid={!!errors.availability}
                        validationMessage={errors.availability}
                        onBlur={handleBlur}
                        inputHeight={48}
                    >
                        <option value="unavailable">Indisponibil</option>
                        <option value="preorder">Precomanda</option>
                        <option value="supplier">Stoc Furnizor</option>
                    </SelectField>

                </Pane>

                <Pane display="flex" marginTop={20} gap={10}>
                     <TextInputField
                        flexGrow={0.2}
                        required
                        inputHeight={48}
                        onWheel={(e) => e.target.blur()} // Disable mouse wheel
                        label="Cant. Max / Comanda"
                        description="Anti Scalperi"
                        name="maxQuantity"
                        type="number"
                        value={values.maxQuantity || 0}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validationMessage={errors.maxQuantity}
                    />

                    <TextInputField
                        flexGrow={0.2}
                        inputHeight={48}
                        required
                        type="text"
                        label="Cant. Max / Precomanda"
                        description="0 = preorder inactive"
                        name="preorderQuantity"
                        value={values.preorderQuantity || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validationMessage={errors.preorderQuantity}
                    />

                    <TextInputField
                        flexGrow={0.6}
                        inputHeight={48}
                        required
                        type="date"
                        label="Lansare"
                        name="availableFrom"
                        description="Data Lansare"
                        value={values.availableFrom || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        validationMessage={errors.availableFrom}

                    />
                </Pane>

                <Pane marginTop={10}>
                    <DynamicAttributes
                        category={category}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}/>
                </Pane>
            </Pane>

            {/* Right side */}
            <Pane>
                <Pane elevation={1} padding={16} background="tint1" marginBottom={16}>
                    <Pane>
                        <Checkbox
                            name="active"
                            label="Activ"
                            checked={values.active || false}
                            onChange={handleChange}
                            margin={0}
                        />
                        <Text color="muted">
                            <Small>(produsul poate fi vandut)</Small>
                        </Text>
                    </Pane>

                    <Pane marginTop={5}>
                        <Checkbox
                            name="activeOnline"
                            label="Activ Online "
                            checked={values.activeOnline || false}
                            onChange={handleChange}
                            margin={0}
                        />
                        <Text color="muted">
                            <Small>(produsul apare pe site si pe alte platforme)</Small>
                        </Text>
                    </Pane>

                    <Pane marginTop={5}>
                        <Checkbox
                            name="service"
                            label="Serviciu"
                            checked={values.service || false}
                            onChange={handleChange}
                            margin={0}
                        />
                        <Text color="muted">
                            (produsul este un serviciu, nu un bun fizic)
                        </Text>
                    </Pane>

                    <Pane marginTop={5}>
                        <Checkbox
                            name="prepay"
                            label="Prepay"
                            checked={values.prepay || false}
                            onChange={handleChange}
                            margin={0}
                        />
                        <Text color="muted">
                            (trebuie platit in avans daca nu e in stoc)
                        </Text>
                    </Pane>

                    <Pane marginTop={5}>
                        <Checkbox
                            margin={0}
                            name="oneClickBuy"
                            label={<>One Click Buy <Badge color="red">BETA</Badge> </>}
                            checked={values.oneClickBuy || false}
                            onChange={handleChange}
                        />
                        <Text color="muted">
                            <Small>(Poate fi comandat cu un click - doar events momentan)</Small>
                        </Text>
                    </Pane>
                </Pane>

                <Pane
                    elevation={1}
                    padding={8}
                    marginBottom={8}
                >
                    <Heading size={500} marginBottom={16}>
                        IMAGINI
                    </Heading>

                    <Field component={KFileDrop} name="images" />
                </Pane>

                <Pane
                    paddingBottom={8}
                    marginBottom={8}
                >
                    <Heading size={500} marginBottom={10}>Varianta</Heading>
                    <ProductPicker
                        minimal={true}
                        single={true}
                        value={values.variant}
                        onSubmit={(products) =>
                            setFieldValue(`variant`, products[0])
                        }
                    />
                </Pane>

                <Pane>
                    <ComponentForm
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}/>
                </Pane>
                <Pane>
                    <SelectField
                        label="Furnizor Disponibilitate"
                        name="availabilitySupplierId"
                        value={values.availabilitySupplierId}
                        required
                        onChange={(e) => {
                            setFieldValue("availabilitySupplierId", e.target.value);
                        }}
                        isInvalid={!!errors.availabilitySupplierId}
                        validationMessage={errors.availabilitySupplierId}
                        onBlur={handleBlur}
                        inputHeight={48}
                        >
                        <option value="">Selecteaza un furnizor</option>
                        {suppliers.map((supplier) => (
                            <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                            </option>
                        ))}
                    </SelectField>
                </Pane>

                <Heading size={500} marginTop={10}>Nota Interna</Heading>
                <Textarea
                    id="comment"
                    label=""
                    name="comment"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={errors.comment}
                    value={values.comment}
                    placeholder="Nota interna produs"
                    />

            </Pane>
        </Pane>
    </>
    )
}
