import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Heading, Pane } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import ImportProductsConfig from '../components/ImportProducts/ImportProductsConfig';
import ImportProductsForm from '../components/ImportProducts/ImportProductsForm';

class ImportProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            supplier: null
        }
    }

    importProducts = (products, supplier) => {
        const supplierId = supplier === 'format-alma-artex' ?
        39 : supplier === 'format-gamesworkshop' ? 14 : null;

        if(!supplier) {
            return;
        }

        this.setState({
            products,
            supplier: supplierId
        });
    }

    render() {
        return (<Pane display="flex" flexDirection="column" gap={10}>
            <Pane>
                <Link to="/products">
                    <Button iconBefore="arrow-left">
                        Inapoi la lista produse
                    </Button>
                </Link>
            </Pane>
            <Pane>
                <Heading size={600}>
                    IMPORT PRODUSE
                </Heading>
            </Pane>

            <Pane>
                <ImportProductsForm handleProductImport={this.importProducts}/>
            </Pane>
            <Pane>
                <ImportProductsConfig products={this.state.products} supplier={this.state.supplier}/>
            </Pane>
        </Pane>)
    }
}

function mapStateToProps(state) {
    return {products: state.products, categories: state.categories};
}

export default connect(mapStateToProps)(ImportProducts);
